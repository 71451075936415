<template>
  <div>
    <c-tab
      type="horizon"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :param="popupParam"
          @closePopup="closePopup"
          @insertTraningInfo="updateMode"
        />
      </template>
    </c-tab>
  </div>
</template>
1
<script>
export default {
  name: 'chem-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopEmergencyTrainingId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'scenario',
      tabItems: [
        { name: 'scenario', icon: 'info', label: '밀폐공간작업 훈련계획 상세', component: () => import(`${'./closedSpaceEduInfo.vue'}`) },
      ],
      addTabItems: [
        { name: 'assess', icon: 'assessment', label: '훈련 평가', component: () => import(`${'./closedSpaceEduAssess.vue'}`) },
      ],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  methods: {
    init() {
      if (this.popupParam.sopEmergencyTrainingId != '') {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    updateMode(_sopEmergencyTrainingId) {
      this.popupParam.sopEmergencyTrainingId = _sopEmergencyTrainingId;
      // 일괄저장할 때 이부분이 없으면 훈련평가 탭이 누를때마다 추가됨
      if(this.tabItems.length > 1) {
        //
      } else {
        this.tabItems = this.tabItems.concat(this.addTabItems);
      }
    },
    closePopup() {
      this.$emit('closePopup');  
    }
  }
};
</script>